.navBar {
  &__items {
    color: white;
    width: 100%;
    height: 100%;
    @include flex(column, space-evenly, flex-end);
    padding-right: 50px;
  }
  &__item {
    color: white;
    list-style: none;
  }
  &__link {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
  }
}
