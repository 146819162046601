.ticker {
  position: fixed;
  top: 10vh;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: black;
  @include mq(tablet) {
    background-color: rgba(0, 0, 0, 0.8);
  }
  color: white;
  &__span {
    margin: 0 15px 0 15px;
  }
}
