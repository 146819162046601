.gallery {
  @include flex(column, center, center);
  width: 100%;
  color: white;
  text-align: center;
  &__title {
    text-transform: uppercase;
  }
  &__eventsList {
    @include flex(row, center, center);
    flex-wrap: wrap;
    @include mq(tablet) {
      min-height: 90vh;
    }
  }
  &__link-container {
    @include size(150px, 150px);
    margin: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid white;
    border-radius: 15px;
    overflow: hidden;
    @include mq(tablet) {
      @include size(250px, 250px);
    }
  }
  &__link {
    @include size(100%, 100%);
    @include flex(column, space-evenly, center);
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
  }
  @include mq(tablet) {
    &__link-container:hover {
      border: 5px solid white;
    }
    &__link:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}
