@mixin mq($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @if ($size) {
    @media (#{$size}) {
      @content;
    }
  } @else {
    @error '"#{$breakpoint}" - nie ma takiej wielkości';
  }
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}
@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
