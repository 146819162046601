.home {
  &__start {
    @include size(100%, 90vh);
    @include flex(row, center, center);
    color: white;
    background-color: transparent;
  }
  &__about,
  &__competitions,
  &__statute,
  &__gallery,
  &__contact {
    @include size(90%, 100%);
    margin: 0 auto;
    color: white;
  }
  &__bgc {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__title {
    text-transform: uppercase;
    font-size: 50px;
    text-align: center;
    font-style: italic;
  }
  &__subtitle {
    text-transform: uppercase;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  &__text {
    text-align: justify;
    padding-bottom: 40px;
  }
}
