.slider {
  position: relative;
  width: 100%;
  margin-bottom: 100px;
  &__dots {
    width: 90%;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__dot {
    width: 10px;
    height: 10px;
    border: 1px solid black;
    border-radius: 50%;
    background-color: white;
  }
  &__images {
    width: 100%;
  }
  &__dot--active {
    background-color: red;
  }
}
