.navDesktop {
  @include size(100%, 10vh);
  @include flex(row, center, center);
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.8);
  z-index: 1;
  &__logo {
    flex-grow: 1;
    height: 90%;
    display: block;
    margin-left: 5%;
    img {
      height: 100%;
      background-color: white;
      border-radius: 50%;
    }
  }
  &__items {
    @include flex(row, space-evenly, center);
    height: 100%;
    flex-basis: 40%;
    margin: 0;
    padding: 0;
  }

  &__item {
    height: 100%;
    list-style: none;
    @include flex(row, space-evenly, center);
  }

  &__item:hover {
    margin-top: 20px;
  }
  &__link {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
  }
  &__link:hover {
    color: gray;
  }
}
