.about {
  @include flex(column, center, center);
  @include mq(tablet) {
    @include flex(row, center, center);
  }
  &__text,
  &__slider {
    @include mq(tablet) {
      width: 50%;
      margin: 20px;
    }
  }
  &__link {
    color: white;
  }
}
