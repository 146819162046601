.results {
  @include flex(column, center, center);
  width: 100%;
  &__title {
    color: white;
    margin-bottom: 20px;
  }
  &__table {
    width: 90%;
  }
}
