.statutes {
  text-align: center;
  color: white;
  padding-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  @include mq(tablet) {
    background-color: transparent;
  }
  &__list {
    width: 90%;
    margin: 0 auto;
    text-align: justify;
    @include mq(tablet) {
      background-color: rgba(0, 0, 0, 0.7);
      padding-right: 10px;
    }
  }
  &__subpoints {
    margin-left: 30px;
  }
}
