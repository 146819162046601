.pages {
  width: 100%;
  padding-top: 10vh;
  background-image: url('../../assets/images/backgroundImage/panoramaMobile.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @include mq(tablet) {
    background-image: url('../../assets/images/backgroundImage/panorama.jpg');
  }
}
.no-scroll {
  overflow: hidden;
}
