.navMobile {
  @include size(100vw, 10vh);
  @include flex(row, space-between, center);
  position: fixed;
  background-color: black;
  color: white;
  z-index: 1;
  &__logo {
    margin-left: 20px;
    display: block;
    @include size(50px, 50px);
    background-color: white;
    border-radius: 50%;
    img {
      @include size(100%, 100%);
    }
  }
  &__hamburger {
    margin-left: 50%;
  }
  &__bar {
    position: fixed;
    top: 10vh;
    bottom: 0;
    // right: 0;
    left: 10;
    width: 90%;
    background-color: black;
    transition: 0.5s;
  }
}
