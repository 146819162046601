.contact {
  padding-bottom: 20px;
  &__partners {
    @include flex(row, space-evenly, center);
    flex-wrap: wrap;
    width: 100%;
  }
  &__image {
    @include size(100px, 100px);
    display: block;
    margin: 10px;
    border-radius: 50%;
    background-color: white;
  }
  &__links {
    color: white;
  }
  &__link {
    color: white;
    list-style: none;
    font-size: 20px;
    margin: 5px;
    text-decoration: none;
  }
}
