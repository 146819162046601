.startPage {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    height: 50%;
    width: auto;
    transform: translate(-50%, -50%) scale(1);
  }
}
.deactive {
  animation: startPageAnimation 4s linear forwards;
}
.deactiveLogo {
  animation: startPageLogo 0.5s linear forwards;
}

@keyframes startPageAnimation {
  0% {
    background-color: black;
  }

  100% {
    background-color: transparent;
    display: none;
  }
}
@keyframes startPageLogo {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    top: 5%;
    left: 10%;
    transform: translate(-50%, -50%) scale(0.2);
    display: none;
  }
}
