.competitions {
  color: white;
  text-align: center;
  padding-bottom: 20px;
}

.backgroundColor {
  background-color: transparent;
  color: white;
}
.accordion {
  width: 90%;
  margin: auto;
}
.accordion-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.accordion-button:focus {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.accordion-button:not(.collapsed) {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.accordion-body {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
