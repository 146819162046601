.statute {
  padding-bottom: 20px;
  &__list {
    list-style: none;
  }
  li:before {
    content: '-';
  }
  &__items {
    color: white;
    margin-left: 10px;
  }
}
