.footer {
  @include size(100%, 100%);
  @include flex(column, center, center);
  background-color: black;
  color: white;
  padding-top: 30px;
  @include mq(tablet) {
    @include flex(row, center, center);
    flex-wrap: wrap;
  }
  &__leftSide,
  &__rightSide {
    @include mq(tablet) {
      flex-basis: 50%;
      flex-grow: 1;
      @include flex(column, center, center);
    }
  }

  &__author {
    text-align: center;
    border-top: 1px solid white;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    @include mq(tablet) {
      order: 3;
    }
  }

  &__link {
    color: white;
  }
}
