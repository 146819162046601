.socials {
  &__wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-basis: 10%;
    padding-right: 20;
  }
  &__link {
    color: white;
  }
  &__item {
    color: white;
  }
  &__link:hover {
    color: gray;
  }
}
