.socialsMobile {
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__item {
  }
  &__link {
    color: white;
    font-size: 30px;
    margin-left: 30px;
  }
}
