.yt {
  @include flex(column, center, center);
  @include size(100%, 100%);
  color: white;

  &__films {
    width: 90%;
    @include mq(tablet) {
      @include flex(row, center, center);
      flex-wrap: wrap;
    }
  }
  &__title {
    text-align: center;
    text-transform: uppercase;
  }

  &__film {
    @include flex(column, flex-start, center);
    border: 1px solid white;
    border-radius: 10px;
    margin: 10px;
    text-align: justify;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.8);
    @include mq(tablet) {
      width: 30%;
      min-height: 400px;
    }
  }
  &__player {
    border-radius: 10px;
    width: 90%;
  }
  &__titleVideo {
  }
  &__description {
    text-align: justify;
    font-size: 15px;
  }
}
